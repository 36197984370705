<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon> {{ ui.snackbar.icon }}</v-icon>
      {{ ui.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs"> mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->


    <!-- Room admins  -->
    <v-row>
      <v-card-title>
        {{ $t('room.admin.title') }}
      </v-card-title>
    </v-row>
    <v-card class="mb-4 text-end" elevation="0" color="transparent">
      <v-btn outlined color="primary" class="ml-2 mb-10" @click="show_dialog_add=true">
        {{ $t('room.admin.add_admin.action_button') }}
      </v-btn>

      <!-- DIALOG ADD NEW ADMINS     -->
      <v-dialog
          v-model="show_dialog_add"
          width="500">
        <v-overlay :value="add_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-form ref="form" v-model="valid" v-on:submit.prevent="addAdmin" lazy-validation>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{ $t('room.admin.add_admin.form.title') }}
              <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-3 pb-3">
              {{ $t('room.admin.add_admin.form.subtitle') }}
              <v-text-field
                  @keydown.space.prevent
                  v-model="add_admin_email"
                  outlined
                  dense
                  autofocus
                  :rules="[rules.required, rules.email]"
                  type="text"
              ></v-text-field>
              <v-checkbox
                  v-model="admin_to_add_is_member"
                  :label="this.$t('room.admin.add_admin.form.data.member.label')"
                  dense
                  hide-details
              ></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="success"
                  text
                  @click="addAdmin">
                {{ $t('room.admin.add_admin.form.button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <div class="">
        <div class="row justify-end">
          <v-col cols="12" sm="6" lg="3" md="4">
            <v-text-field
                :placeholder="this.$t('room.admin.list.datatable.search')"
                v-model="datatable.search">
              <v-icon slot="prepend" color="primary">mdi-magnify</v-icon>
            </v-text-field>
          </v-col>
        </div>
      </div>
      <!-- ROOM MEMBERS DATATABLE-->
      <v-data-table style="max-height: 400px; overflow:auto" :headers="datatable.headers"
                    :items="admins"
                    :items-per-page.sync="datatable.items_per_page"
                    :search="datatable.search"
                    @pagination="paginationHandler"
                    class="datatable"
      >
        <template v-slot:item.user_email="{ item }">
                <span class="font-weight-bold">
                  {{ !_.isEmpty(item.user_email) && item.user_email !== 'False' ? item.user_email : '' }}
                </span>
        </template>
<!--        <template v-slot:item.user_login="{ item }" class="text-center">
          <span class="font-weight-bold">
                  {{ item.user_login }}
                </span>
        </template>-->
        <template v-slot:item.user_nickname="{ item }" class="text-center">
          <span class="font-weight-bold">
                  {{ item.user_nickname }}
                </span>
        </template>
        <template v-slot:item.delete="{ item }" class="text-center">
          <v-icon @click="showDeleteAdmin(item)" color="primary">mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <!--END ROOM MEMBERS DATATABLE-->

      <!--DIALOG DELETE ADMIN      -->
      <v-dialog
          v-model="show_dialog_delete"
          width="500"
      >
        <v-overlay :value="delete_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.admin.delete_admin.form.title') }}
            <v-icon color="white" @click="show_dialog_delete=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class=" pt-3">
            {{$t('room.admin.delete_admin.form.subtitle')}}
            <strong>{{
                !_.isUndefined(dialog_delete_admin_object.user_email) ? dialog_delete_admin_object.user_email : ''
                    }}</strong> ?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                @click="deleteAdmin"
            >
              {{ $t('room.admin.delete_admin.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--END DIALOG DELETE MEMBER      -->
    </v-card>
  </v-card>
</template>

<script>
import keepSessionData from "@/tools/KeepSessionData";

export default {
  name: "Admins",
  mixins: [keepSessionData],
  data() {
    return {
      // admins
      admins: [],
      datatable: {
        search: '',
        headers: [],
        options: {},
        current_page: 1,
        items_per_page: parseInt(this.restoreData('pagination',5)),
      },

      // delete admin
      show_dialog_delete: false,
      dialog_delete_admin_object: '',
      delete_loading: false,

      // add admin
      show_dialog_add: false,
      add_admin_email: '',
      admin_to_add_is_member: false, // checkbox : set admin as member
      add_loading: false,
      valid: true,

      rules: {
        required: value => !!value || this.$t('room.admin.add_admin.rules.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('room.admin.add_admin.rules.invalid_email')
        }
      },
      // ui
      loading: false,
      ui: {
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1,
          multiline: false
        }
      }
    }
  },
  watch: {
    /**
     * Clean add member dialog on close
     * */
    show_dialog_add: function () {
      if (this.show_dialog_add === false) {
        this.add_admin_email = ''
        this.admin_to_add_is_member = false
        this.$refs.form.resetValidation()
      }
    },
  },
  created() {
    // Define translations
    this.datatable.headers = [
      {
        value: 'user_email',
        sortable: true,
        filterable: true,
        text: this.$t('room.admin.list.datatable.email')
      },
      /*{
        value: 'user_login',
        sortable: true,
        text: "Login",
      },*/
      {
        value: 'user_nickname',
        text: this.$t('room.admin.list.datatable.nickname'),
      },
      {
        text: this.$t('room.admin.list.datatable.delete'),
        align: 'center',
        value: 'delete',
        sortable: false
      }
    ]
  },
  methods: {
    /**
     * @param {Object} e
     */
    paginationHandler(e) {
      if(this.datatable.items_per_page === e.itemsPerPage)
        this.keepData('pagination', e.itemsPerPage)
    },
    /**
     * Adds admin to the room
     *
     * @return {Promise<void>}
     */
    async addAdmin() {
      if (this.$refs.form.validate()) {

        this.add_loading = true

        try {
          await this.axios.post('/room/admin/add', {
            auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
            room_key: this.$route.params.room_key,
            user_email: this.add_admin_email,
            member: this.admin_to_add_is_member
          })

          this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.admin.add_admin.form.success_message'), '4000')

          await this.fetchRoomAdmins()

        } catch (e) {
          if (!this._.isUndefined(e.response?.status)) {
            switch (parseInt(e.response.status)) {
                // User is already admin on room
              case 302:
                this.snackbar('warning', 'mdi-alert-circle-outline', this.$t('room.admin.add_admin.form.error.already_admin'), '4000')
                break

                //Invalid parameters
              case 400:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.add_admin.form.error.invalid_params'), '4000')
                break

                //Invalid token
              case 401:
                this.$session.destroy()
                await this.$router.push({name: "Home"})
                break

                // User does not have access to room
              case 402:
                await this.$router.push({name: "Home"})
                setTimeout(() => {
                  this.$root.$emit('home-error', 'no-access')
                }, 1000)
                break

                // Room does not exist
              case 404:
                await this.$router.push({name: "Home"})
                setTimeout(() => {
                  this.$root.$emit('home-error', 'no-room')
                }, 1000)
                break

                // Method not allowed
              case 405:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.add_admin.form.error.invalid_method'), '4000')
                break

                // Cannot save to DB
              default:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.add_admin.form.error.unknown'), '4000')
            }
          }
        } finally {
          this.add_loading = false
          this.show_dialog_add = false
          this.resetAddForm()
        }
      }
    },
    /**
     * Reset form in dialog add user
     * */
    resetAddForm() {
      this.add_admin_email = ''
      this.admin_to_add_is_member = false
      this.$refs.form.resetValidation()
    },

    /**
     * Deletes admin
     * @return {Promise<void>}
     * */
    async deleteAdmin() {
      this.delete_loading = true

      try {
        await this.axios.post('/room/admin/delete', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          user_email: this.dialog_delete_admin_object.user_email,
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.admin.delete_admin.success_message'), '4000')

        await this.fetchRoomAdmins()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.delete_admin.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.delete_admin.error.invalid_method'), '4000')
              break

              // User does not exist or is not an admin of this room
            case 302:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.delete_admin.error.admin_not_exist'), '4000')
              await this.fetchRoomAdmins()
              break

              // Target user is authenticated user
            case 409:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.delete_admin.error.is_himself'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.delete_admin.error.unknown'), '4000')
          }
        }

      } finally {
        this.delete_loading = false
        this.show_dialog_delete = false
      }
    },
    /**
     * Show delete member dialog
     * */
    showDeleteAdmin(param) {
      this.show_dialog_delete = true
      this.dialog_delete_admin_object = param
    },
    snackbar(type, icon, message, timeout, multiline) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
      this.ui.snackbar.multiline = multiline
    },
    /**
     * Fetch room admins
     * @return {Promise<void>}
     */
    async fetchRoomAdmins() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/admin/list', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key
        })).data

        this.admins = [...response.room_admins.admins_active, ...response.room_admins.admins_pending]

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.admin.list.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.fetchRoomAdmins()
  }
}

</script>

<style scoped>

</style>
