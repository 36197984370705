<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
        :multi-line="ui.snackbar.multiline"
    >
      <v-icon class="align-baseline"> {{ ui.snackbar.icon }}</v-icon>
      <v-col class="pt-0 pb-0 pr-0">
        <span v-html="ui.snackbar.message"></span>
      </v-col>
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs" class="pt-2 snackbar-close">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->

    <!-- Licences  -->
    <v-row>
      <v-card-title>
        {{ $t('room.license.title') }}
      </v-card-title>
    </v-row>
    <v-card class="mb-4 text-end" elevation="0" color="transparent">
      <!--      <v-btn outlined class="ml-2 mb-10" @click="show_dialog_add=true">-->
      <v-btn outlined class="ml-2 mb-10" @click="start">
        {{ $t('room.license.add_license.action_button') }}
      </v-btn>


      <!-- DIALOG ADD NEW LICENCE    -->
      <v-dialog
          v-model="show_dialog_add"
          width="500"
      >
        <v-overlay :value="add_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card v-if="is_email_confirmed">
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.license.add_license.form.title') }}
            <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-3 pb-3">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1 "
                dense
            >
              {{ ui.alert.message }}
            </v-alert>
            {{ $t('room.license.add_license.form.data.code') }}
            <v-text-field class="pt-3 pb-3"
                          v-model="add_licence_code"
                          outlined
                          dense
                          hide-details
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                :disabled="_.isEmpty(add_licence_code)"
                @click="addLicence"
            >
              {{ $t('room.license.add_license.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>


        <v-card v-else-if="!needs_add_email">
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.license.add_license.form.confirm_email.title') }}
            <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-3 pb-3">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1 "
                dense
            >
              {{ ui.alert.message }}
            </v-alert>
            {{ $t('room.license.add_license.form.confirm_email.text') }}
            <v-text-field class="pt-3 pb-3"
                          v-model="token_confirmation"
                          outlined
                          dense
                          :placeholder="this.$t('room.license.add_license.form.confirm_email.code.placeholder')"
                          hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <div class="text-caption">
              {{
                $t('room.license.add_license.form.confirm_email.no_email')
              }}
              <a href="mailto:support@sofa-collaboration.com">support@sofa-collaboration.com</a><br><br>
            </div>
            <div class="text-caption">
              <a @click="generateConfirmation">{{ $t('room.license.add_license.form.confirm_email.resend') }}</a><br>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                :disabled="_.isEmpty(token_confirmation)"
                @click="confirmEmail"
            >
              {{ $t('room.license.add_license.form.confirm_email.button.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="!is_email_confirmed && needs_add_email">
          <v-card-title class="primary white--text justify-space-between">
            Confirm email
            <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-3 pb-3">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1 "
                dense

            >
              {{ ui.alert.message }}
            </v-alert>
            Rentrez votre adresse email.
            <v-form ref="emailForm">
              <v-text-field class="pt-3 pb-3"
                            v-model="email_to_add"
                            outlined
                            required
                            :rules="[rules.email]"
                            dense
                            validate-on-blur
                            placeholder="addrese email"
              ></v-text-field>
            </v-form>
            <v-spacer></v-spacer>
            <div class="text-caption">
              Si vous rencontrez des difficultés vous pouvez contacter le support :
              <a href="mailto:support@sofa-collaboration.com">support@sofa-collaboration.com</a>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                :disabled="_.isEmpty(email_to_add) || !rules.email"
                @click="addEmail"
            >
              Save
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <div class="">
        <div class="row justify-end">
          <v-col cols="12" sm="6" lg="3" md="4">
            <v-text-field
                :placeholder="this.$t('room.license.list.datatable.search')"
                v-model="datatable.search">
              <v-icon slot="prepend" color="primary">mdi-magnify</v-icon>
            </v-text-field>
          </v-col>
        </div>
      </div>

      <!-- ROOM LICENCES DATATABLE-->
      <v-data-table style="max-height: 400px; overflow:auto" :headers="datatable.headers"
                    :items="licences"
                    :items-per-page.sync="datatable.items_per_page"
                    :search="datatable.search"
                    @pagination="paginationHandler"
                    class="datatable"
      >
        <template v-slot:item.catalog="{ item }">
                <span class="">
                  {{ item.catalog }}
                </span>
        </template>
        <template v-slot:item.status="{ item }" class="text-center">
          <span class="">
                  {{ item.status }}
                </span>
        </template>
        <template v-slot:item.code="{ item }" class="text-center">
          <span class="">
                  {{ item.code }}
                </span>
        </template>
        <template v-slot:item.max_user="{ item }" class="text-center">
          <span class="">
                  {{ item.max_user }}
                </span>
        </template>
        <template v-slot:item.activated_at="{ item }" class="text-center">
          <span class="">
                  {{
              new Date(item.activated_at).toLocaleDateString('en-gb', date_format_options_list).replace(',', '')
                  }}
                </span>
        </template>
        <template v-slot:item.expires_at="{ item }" class="text-center">
          <span :class="{'error--text font-weight-medium' : Date.now() > new Date(item.expires_at) }">
            {{ new Date(item.expires_at).toLocaleDateString('en-gb', date_format_options_list).replace(',', '') }}
          </span>
        </template>
      </v-data-table>
      <!--END ROOM LICENCES DATATABLE-->
    </v-card>
    <!-- End room licences  -->
  </v-card>

</template>

<script>
import keepSessionData from "@/tools/KeepSessionData";

export default {
  name: "Licence",
  mixins: [keepSessionData],
  data() {
    return {
      // licences
      datatable: {
        search: '',
        headers: [],
        options: {},
        current_page: 1,
        items_per_page: parseInt(this.restoreData('pagination',5)),
      },
      licences: [],
      date_format_options_list: {
        year: "numeric",
        month: 'numeric',
        day: 'numeric'
      },

      // add licence
      is_email_confirmed: false,
      token_confirmation: '',
      needs_add_email: false,
      email_to_add: '',
      add_licence_code: '',

      show_dialog_add: false,
      add_loading: false,

      rules: {
        required: value => !!value || this.$t('room.license.add_license.form.rules.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('room.license.add_license.form.rules.invalid_email')
        },
      },

      // ui
      loading: false,
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        },
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1
        }
      }
    }
  },
  watch: {
    /**
     * Clean add licence dialog on close
     * */
    show_dialog_add: function () {
      if (this.show_dialog_add === false) {
        this.ui.alert.enable = false
        this.add_licence_code = ''
        this.token_confirmation = ''
        this.email_to_add = ''
      }
    },
    is_email_confirmed: function () {
      this.ui.alert.enable = false
    }
  },
  created() {
    // Define translations
    this.datatable.headers = [
      {
        value: 'catalog',
        text: this.$t('room.license.list.datatable.license')
      },
      {
        value: 'status',
        text: this.$t('room.license.list.datatable.license_status'),
      },
      {
        value: 'code',
        text: this.$t('room.license.list.datatable.license_code')
      },
      {
        value: 'max_user',
        text: this.$t('room.license.list.datatable.max_user')

      },
      {
        value: 'activated_at',
        text: this.$t('room.license.list.datatable.activated_at')

      },
      {
        value: 'expires_at',
        text: this.$t('room.license.list.datatable.expires_at')
      },

    ]
  },
  methods: {
    /**
     * @param {Object} e
     */
    paginationHandler(e) {
      if (this.datatable.items_per_page === e.itemsPerPage)
        this.keepData('pagination', e.itemsPerPage)
    },
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    snackbar(type, icon, message, timeout) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
    },
    /**
     * Adds licence to a room
     *
     *
     * @return {Promise<void>}
     */
    async addLicence() {
      this.show_dialog_add = true

      this.add_loading = true

      try {
        await this.axios.post('/room/license/add', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          license_code: this.add_licence_code,
          room_key: this.$route.params.room_key
        })

        this.alert('success', 'mdi-check-circle-outline', this.$t('room.license.add_license.form.success_message'), true)

        await this.fetchLicences()

        this.add_licence_code = ''

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              //Invalid parameters
            case 400:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.invalid_params'), true)
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Invalid license code
            case 460:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.license_invalid'), true)
              break

              // License cannot be attached : not pending, already active or expired
            case 461:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.license_expired'), true)
              break

              // Incorrect license kind for room
            case 462:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.license_incompatible'), true)
              break

              // Method not allowed
            case 405:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.invalid_method'), true)
              break

              // Cannot save to DB
            default:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.error.unknown'), true)
          }
        }
      } finally {
        this.add_loading = false
      }


    },
    /**
     * Generates email confirmation token and sends email to user with code
     *
     * */
    async generateConfirmation() {
      try {
        this.add_loading = true

        await this.axios.post('/user/email/token', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null
        })

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.generate_confirmation_token.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User email not confirmed but email in db => send confirmation token by email
            case 402:
              this.is_email_confirmed = false
              this.ui.alert.enable = false
              break

              // User email not confirmed and no email in db => save email to db
            case 403:
              this.is_email_confirmed = false
              this.needs_add_email = true
              this.ui.alert.enable = false
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.generate_confirmation_token.error.unknown'), '4000')
          }
        }
      } finally {
        this.add_loading = false
      }
    },
    /**
     * Confirm user email using token send by email
     * */
    async confirmEmail() {
      try {
        this.add_loading = true

        await this.axios.post('/user/email/confirm', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          token: this.token_confirmation
        })

        this.needs_add_email = false
        this.is_email_confirmed = true
        this.show_dialog_add = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // Confirmation token mismatch
            case 403:
              this.alert('error', 'mdi-alert-octagon', this.$t('room.license.add_license.form.confirm_email.error.token_mismatch'), true)
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.error.unknown'), '4000')
          }
        }
      } finally {
        this.add_loading = false
      }
    },
    /**
     * Get all licences for a room
     *
     * @return {Promise<void>}
     */
    async fetchLicences() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/license/list', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key
        })).data

        this.licences = response.licences

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.list.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Start checks for renewing license
     * */
    async start() {
      await this.checkEmailConfirmed()
    },
    /**
     * Check if user email is confirmed
     * If not:
     *      Check if user has email address
     *            If not : add user email (show dialog with user email)
     *            If yes : Send confirmation token (show dialog with confirmation token)
     * If yes:
     *      Show dialog with add licence
     * @return {Promise<void>}
     */
    async checkEmailConfirmed() {
      try {
        this.loading = true
        this.add_loading = true

        await this.axios.post('/user/email-confirmed', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null
        })

        this.is_email_confirmed = true
        this.show_dialog_add = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.check_email_confirmed.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User email not confirmed but email in db => send confirmation token by email
            case 402:
              this.is_email_confirmed = false
              this.needs_add_email = false
              await this.generateConfirmation()
              break

              // User email not confirmed and no email in db => save email to db
            case 403:
              this.is_email_confirmed = false
              this.needs_add_email = true
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.license.add_license.form.confirm_email.check_email_confirmed.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
        this.show_dialog_add = true
        this.add_loading = false
      }
    },
    /**
     * Add email to user if none in db
     *
     * @return {Promise<void>}
     */
    async addEmail() {
      try {
        this.loading = true
        this.add_loading = true

        if (this.$refs.emailForm.validate()) {

          await this.axios.post('/user/email/add', {
            auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
            email: this.email_to_add
          })

          await this.checkEmailConfirmed()
        }
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid values passed in parameters.', '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }
        }
      } finally {
        this.loading = false
        this.add_loading = false
        this.show_dialog_add = true
      }
    }
  },
  async mounted() {
    await this.fetchLicences()

  }
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .v-snack__content {
    display: flex;
  }

  .v-snack__action {
    align-self: baseline !important;

    button .v-icon {
      font-size: 20px;
    }

    .snackbar-close {
      font-size: 20px;
    }
  }

  .text-caption {
    font-family: Poppins, serif !important;
  }
}
</style>
