<template>
  <v-card style="height: 100%;">
    <v-tabs
        v-model="tab"
        background-color="white accent-4"
        grow
        show-arrows
    >
      <v-tab
          v-for="item in items"
          :key="item.key"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <General v-if="tab===0"></General>
      </v-tab-item>
      <v-tab-item>
        <Management v-if="tab===1"></Management>
      </v-tab-item>
      <v-tab-item>
        <Admins v-if="tab === 2"></Admins>
      </v-tab-item>
      <v-tab-item>
        <Licence v-if="tab === 3"></Licence>
      </v-tab-item>
      <v-tab-item>
        <MeetingLink v-if="tab === 4"></MeetingLink>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import General from "./components/General";
import Management from "./components/Management";
/*import Box from "./components/Box"*/
import Admins from "./components/Admins"
import Licence from "./components/Licence";
import MeetingLink from "./components/MeetingLink";

export default {
  name: "RoomGeneral",
  components: {
    General,
    Management,
    /*Box,*/
    Admins,
    Licence,
    MeetingLink
  },
  data() {
    return {
      tab: 0,
      items: [
        {tab: this.$t('room.tabs.general'), content: 'Tab 1 Content', key: 1},
        {tab: this.$t('room.tabs.management'), content: 'Tab 2 Content', key: 2},
        /*{tab: 'Box associées', content: 'Tab 3 Content'},*/
        {tab: this.$t('room.tabs.admin'), content: 'Tab 4 Content', key: 3},
        {tab: this.$t('room.tabs.license'), content: 'Tab 5 Content', key: 4},
        {tab:this.$t('room.tabs.meeting_link'), content: 'Tab 6 Content', key: 5}
      ],
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-tab:after {
    content: '';
    height: 60%;
    width: 1px;
    position: absolute;
    right: 0;
    background-color: #790346;
  }

  .v-tab:nth-last-of-type(1):after {
    width: 0px !important;
  }
}
</style>
