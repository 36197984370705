<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon class="align-baseline"> {{ ui.snackbar.icon }}</v-icon>
      <v-col class="pt-0 pb-0 pr-0">
        <span v-html="ui.snackbar.message"></span>
      </v-col>
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs" class="pt-2 snackbar-close">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->

    <!-- General room name   -->
    <v-row>
      <v-card-title>{{$t('room.general.general.title')}}</v-card-title>
    </v-row>
    <v-card-text>
      {{$t('room.general.general.subtitle')}}
    </v-card-text>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card-text>{{$t('room.general.general.form.name')}}</v-card-text>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-model="room_name" outlined dense type="text" @change="editRoomInfos"></v-text-field>
      </v-col>
    </v-row>
    <!-- End General room name   -->

    <v-divider
        class="mt-3 mb-3 primary"></v-divider>

    <!-- General room visibility   -->
    <v-row>
      <v-card-title>{{$t('room.general.access.title')}}</v-card-title>
    </v-row>
    <v-card-text> {{$t('room.general.access.subtitle')}}
      <v-checkbox v-model="is_active" label="Active" dense hide-details @change="editRoomInfos"></v-checkbox>
    </v-card-text>
    <!-- End general room visibility   -->

    <!-- ROOM PASSWORD ON PRIVATE ROOMS    -->
    <v-card-text v-if="room_kind === roomKind.Private">{{
        $t('room.general.password.subtitle')
                                                       }}
      <v-checkbox v-model="room_has_password" :label="this.$t('room.general.password.label')" dense
                  hide-detail @click="openDialogPassword"></v-checkbox>
    </v-card-text>

    <!--Dialog edit password on room    -->
    <v-dialog v-model="dialog_password" width="500px">

      <v-card>
        <v-card-title class="primary white--text justify-space-between">
          {{$t('room.general.password.add_password.form.title') }}
          <v-icon color="white" @click="dialog_password = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="px-O py-0">
          <v-overlay :value="loading_dialog" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <!-- CONTENT IF REMOVING PASSWORD         -->
          <v-form ref="form" v-model="valid" @submit.prevent="editRoomInfos">
            <v-card-text v-if="!room_has_password" class="px-0">
              {{ $t('room.general.password.remove_password.form.message') }}
            </v-card-text>
          </v-form>
          <!--END CONTENT IF REMOVING PASSWORD         -->
          <!-- CONTENT IF ADDING PASSWORD         -->
          <v-card-text v-if="room_has_password" class="px-0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field :label="this.$t('room.general.password.add_password.form.password')" v-model="password"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPassword ? 'text' : 'password'"
                              :rules="[rules.required, rules.password_length, rules.password_letter, rules.password_number]"
                              outlined dense
                              @click:append="showPassword = !showPassword"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-text-field :label="this.$t('room.general.password.add_password.form.password_confirm')" v-model="password_confirm"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPassword ? 'text' : 'password'"
                              ref="password_confirm"
                              :rules="[rules.match]"
                              outlined dense
                              @click:append="showPassword = !showPassword"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>
        <!--END CONTENT IF ADDING PASSWORD         -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="editRoomInfos">{{ $t('room.general.password.add_password.button.save') }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <v-card-text v-if="room_kind === roomKind.Public">Vous pouvez rendre votre salle visible ou encore positionner
                                                      toutes les personnes affilié comme animateur.
      <v-checkbox v-model="is_visible" label="Visible" dense hide-details
                  @change="editRoomInfos"></v-checkbox>
      <v-checkbox v-model="no_moderation"
                  label="Ouverte (Tout le monde est animateur)" dense hide-details @change="editRoomInfos"></v-checkbox>
    </v-card-text>


    <!-- End General room visibility   -->
    <v-divider
        class="mt-3 mb-3 primary"
    ></v-divider>
    <!-- General image   -->
    <v-row>
      <v-card-title>{{ $t('room.general.image.title') }}</v-card-title>
    </v-row>
    <v-list-item>
      <v-list-item-avatar tile style="height: 368px; width: 500px;">
        <v-img :src="displaySnapshot">
          <v-overlay :value="loadingSnapshot" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-img>
      </v-list-item-avatar>
      <!--  DIALOG snapshot   -->
      <v-dialog v-model="dialogSnapshot" width="600" class="dialog-webcam">
        <v-overlay :value="loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" color="primary">{{$t('room.general.image.action_button')}}</v-btn>
        </template>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.general.image.form.title') }}
            <v-icon color="white" @click="dialogSnapshot = false">mdi-close</v-icon>
          </v-card-title>
          <v-spacer></v-spacer>
          <!-- Upload by choosing image or drag n drop         -->
          <v-card-text v-if="!previewSnapshot" class="pt-5">
            <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-6 mt-1"
            >
              {{ ui.alert.message }}
            </v-alert>
            <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragEnter"
                 @dragleave="dragging = false">
              <div class="dropzone-wrapper">
                <div class="dropzone-info text-center">
                  <v-icon color="primary">mdi-upload</v-icon>
                </div>
                <div class="dropzone-info text-center" @drag="onChangeFileUpload($event, 'snapshot')">
                  <span class="dropzone-title">{{ $t('room.general.image.form.image_upload') }}</span>
                </div>
              </div>
              <input type="file" @change="onChangeFileUpload($event, 'snapshot')" accept="image/*">
            </div>
          </v-card-text>
          <!-- End of upload by choosing image or drag n drop  -->
          <div v-if="previewSnapshot">
            <v-overlay :value="loadingImage" absolute>
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-card-text class="pt-5">
              <cropper ref="cropper" class="cropper" :src="previewSnapshot"
                       :stencil-props="{
                        handlers: {},
                        movable: false,
                        resizable: false
                       }"
                       :canvas="{
                          minHeight: 0,
                          minWidth: 0,
                          maxHeight: 368,
                          maxWidth: 500,
                        }"
                       :stencil-size="{
                          width: 500,
                          height: 368
                        }"
                       image-restriction="stencil"
                       @change="onChange"
                       @ready="ready"
                       @error="onError" />
            </v-card-text>
            <!--  CROPPER ACTION BUTTONS          -->
            <v-card-text class="text-center buttons">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(90)">
                    <v-icon>mdi-rotate-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.rotate_left') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="rotate(-90)">
                    <v-icon>mdi-rotate-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.rotate_right') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(true, false)">
                    <v-icon>mdi-flip-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.flip_horizontally') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="flip(false, true)">
                    <v-icon>mdi-flip-vertical</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.flip_vertically') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(2)">
                    <v-icon>mdi-magnify-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.zoom_in') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="zoom(0.5)">
                    <v-icon>mdi-magnify-minus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.zoom_out') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="maximize()">
                    <v-icon>mdi-arrow-expand-all</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.maximize') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs"
                         v-on="on" @click="center()">
                    <v-icon>mdi-image-filter-center-focus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('room.general.image.form.cropper.actions.focus') }}</span>
              </v-tooltip>
            </v-card-text>
            <!-- END CROPPER ACTION BUTTONS          -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="save('snapshot')">
                {{$t('room.general.image.form.button.save') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-list-item>
    <!-- End General image   -->
    <v-divider class="mt-3 mb-3 primary" v-if="room_kind !== roomKind.Public"></v-divider>
    <!-- General image pdf   -->
    <div v-if="room_kind !== roomKind.Public">
      <v-row>
        <v-card-title>{{ $t('room.general.pdf.title') }}</v-card-title>
      </v-row>
      <v-list-item>
        <v-list-item-avatar tile style="width: 350px; height: fit-content">
          <v-img :src="displayPdf" style="object-fit: contain">
            <v-overlay :value="loadingPdf" absolute>
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-img>
        </v-list-item-avatar>
        <!--  DIALOG pdf   -->
        <v-dialog v-model="dialogPdf" width="600" class="dialog-webcam">
          <v-overlay :value="loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on" color="primary">{{$t('room.general.pdf.action_button')}}</v-btn>
          </template>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{$t('room.general.pdf.form.title') }}
              <v-icon color="white" @click="dialogPdf = false">mdi-close</v-icon>
            </v-card-title>
            <v-spacer></v-spacer>

            <!-- Upload by choosing image or drag n drop         -->
            <v-card-text v-if="!previewPdf" class="pt-5">
              <v-alert
                  v-model="ui.alert.enable"
                  :dismissible="ui.alert.dismissible"
                  :icon="ui.alert.icon"
                  :type="ui.alert.type"
                  class="mb-6 mt-1"
              >
                {{ ui.alert.message }}
              </v-alert>
              <div :class="['dropzone', dragging ? 'dropzone-over' : '']" @dragenter="dragEnter"
                   @dragleave="dragging = false">
                <div class="dropzone-wrapper">
                  <div class="dropzone-info text-center">
                    <v-icon color="primary">mdi-upload</v-icon>
                  </div>
                  <div class="dropzone-info text-center" @drag="onChangeFileUpload($event, 'pdf_image')">
                    <span class="dropzone-title">{{ $t('room.general.pdf.form.image_upload') }}</span>
                  </div>
                </div>
                <input type="file" @change="onChangeFileUpload($event, 'pdf_image')" accept="image/*">
              </div>
            </v-card-text>
            <!-- End of upload by choosing image or drag n drop  -->

            <div v-if="previewPdf">
              <v-overlay :value="loadingImage" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-card-text class="pt-5">
                <cropper ref="cropper" class="cropper" :src="previewPdf"
                         :stencil-props="{
                              handlers: {},
                              movable: false,
                              resizable: true
                             }"
                         :canvas="{
                                minHeight: 0,
                                minWidth: 0,
                                maxHeight: 1024,
                                maxWidth: 2048,
                              }"
                         :stencil-size="{
                                width: 2048,
                                height: 1024
                              }"
                         image-restriction="stencil"
                         @change="onChange"
                         @ready="ready"
                         @error="onError" />
              </v-card-text>
              <!--  CROPPER ACTION BUTTONS          -->
              <v-card-text class="text-center buttons">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="rotate(90)">
                      <v-icon>mdi-rotate-left</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.rotate_left') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="rotate(-90)">
                      <v-icon>mdi-rotate-right</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.rotate_right') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="flip(true, false)">
                      <v-icon>mdi-flip-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.flip_horizontally') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="flip(false, true)">
                      <v-icon>mdi-flip-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.flip_vertically') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="zoom(2)">
                      <v-icon>mdi-magnify-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.zoom_in') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="zoom(0.5)">
                      <v-icon>mdi-magnify-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.zoom_out') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="maximize()">
                      <v-icon>mdi-arrow-expand-all</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.maximize') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs"
                           v-on="on" @click="center()">
                      <v-icon>mdi-image-filter-center-focus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('room.general.pdf.form.cropper.actions.focus') }}</span>
                </v-tooltip>
              </v-card-text>
              <!-- END CROPPER ACTION BUTTONS          -->
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="save('pdf_image')">
                  {{ $t('room.general.pdf.form.button.save') }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </v-list-item>
    </div>
    <!-- End General image   -->
  </v-card>
</template>

<script>

import 'vue-advanced-cropper/dist/style.css'
import _ from "@/tools/lodash";


export default {
  name: "General",
  data() {
    return {
      room_kind: '',
      room_name: '',
      init_room_name: '',

      is_active: null,
      init_is_active: null,

      is_visible: null, // only for public rooms
      init_is_visible: null,

      no_moderation: null,
      init_no_moderation: null,

      // password on room
      dialog_password: false,
      password: null,
      password_confirm: null,
      showPassword: false, // show password in dialog
      init_room_has_password: false, // initial value for checkbox
      room_has_password: false, // v-model for checkbox
      open_password: false, // v-model for dialog, it changes content depending on the value
      init_has_password: false, // initial value
      loading_dialog: false, // loader on dialog
      valid: true, // is form valid for password change

      form_valid: true,


      loading: false,
      rules: {
        required: value => !!value || this.$t('room.general.password.add_password.form.rules.required'),
        match: value => {
          if (this.password) {
            return value === this.password || this.$t('room.general.password.add_password.form.rules.no_match')
          } else return true
        },
        password_length: value => (value === null || value.length >= 8 || value.length === 0) || this.$t('room.general.password.add_password.form.rules.password_length'),
        password_number: value => {
          if (value) {
            const pattern = /\d/
            return pattern.test(value) || this.$t('room.general.password.add_password.form.rules.password_number')
          } else return true
        },
        password_letter: value => {
          if (value) {
            const pattern = /[a-zA-Z]/
            return pattern.test(value) || this.$t('room.general.password.add_password.form.rules.password_letter')
          } else return true
        }
      },

      roomKind: {
        'Private': 0,
        'Team': 1,
        'Public': 2
      },

      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        },
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1,
          multiline: false
        }
      },


      loadingImage: false,
      loadingSnapshot: false,
      snapshot: null,
      displaySnapshot: '',
      dialogSnapshot: false,
      previewSnapshot: undefined,
      currentSnapshot: undefined,
      loadingPdf: false,
      pdf: null,
      displayPdf: '',
      dialogPdf: false,
      previewPdf: undefined,
      currentPdf: undefined,
      size: {
        width: null,
        height: null,
      },
      result: {
        coordinates: null,
        image: null
      },
      dragging: false,

    }
  },
  watch: {
    /**
     * Clean edit image dialog on close
     * */
    dialogSnapshot: function () {
      if (this.dialogSnapshot === false) {
        this.previewSnapshot = undefined
        this.ui.alert.enable = false
      }
    },
    /**
     * Clean edit pdf image dialog on close
     * */
    dialogPdf: function () {
      if (this.dialogPdf === false) {
        this.previewPdf = undefined
        this.ui.alert.enable = false
      }
    },
    /**
     * Clean edit password dialog on close
     * If no changes saved, restore checkbox value
     *
     * */
    dialog_password: function () {
      if (this.dialog_password === false) {
        this.password = null
        this.password_confirm = null
        this.showPassword = false
        this.ui.alert.enable = false

        this.room_has_password = this.init_room_has_password
      }
    },
    password: function() {
      this.$refs.password_confirm.validate()
    }
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    snackbar(type, icon, message, timeout, multiline) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
      this.ui.snackbar.multiline = multiline
    },
    /**
     * Open dialog change password/remove password
     * */
    openDialogPassword() {
      this.dialog_password = true
    },
    /**
     * Init and reset v-model values
     * */
    resetValues() {
      // update v-model values with initial values
      this.room_name = this.init_room_name
      this.is_active = this.init_is_active
      this.is_visible = this.init_is_visible
      this.no_moderation = this.init_no_moderation
      this.room_has_password = this.init_room_has_password
    },
    /**
     * Edit and save
     * room infos
     * @returns {Promise<void>}
     **/
    async editRoomInfos() {

      // loader only on dialog password
      if (this.dialog_password) {
        this.form_valid = this.$refs.form.validate()
        this.loading_dialog = true
      } else
        this.loading = true

      if (this.form_valid) {
        try {
          await this.axios.post('/room/general/edit', {
            auth_token: this.$session.get('token'),
            room_key: this.$route.params.room_key,
            display_name: this.room_name,
            is_active: this.is_active,
            room_moderation: this.no_moderation,
            is_visible: this.is_visible,
            remove_room_password: !this.room_has_password,
            room_password: this.password
          })

          // emit event to topbar to change room name
          this.$root.$emit('room-name-changed', this.room_name, this.room_kind)

          this.dialog_password = false

          this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.general.room_info_modify.success_message'), '4000')

          await this.getRoomInfos()

        } catch (e) {
          if (!this._.isUndefined(e.response?.status)) {
            switch (parseInt(e.response.status)) {

                //Invalid parameters
              case 400:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_modify.error.invalid_params'), '4000')
                break

                //Invalid token
              case 401:
                this.$session.destroy()
                await this.$router.push({name: 'Login'})
                break;

                // User does not have access to room
              case 402:
                await this.$router.push({name: "Home"}).then(() => {
                  this.$root.$emit('home-error', 'no-access')
                })
                break

                // Room does not exist
              case 404:
                await this.$router.push({name: "Home"}).then(() => {
                  this.$root.$emit('home-error', 'no-room')
                })
                break

                // Method not allowed
              case 405:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_modify.error.invalid_method'), '4000')
                break

                // Cannot save to DB
              case 418:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_modify.error.db_error'), '4000')
                break

              default:
                this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_modify.error.unknown'), '4000')
            }
          }
        } finally {
          this.loading = false
          this.loading_dialog = false
          this.resetValues()
        }
      }
    },
    /**
     * Fetch and display
     * room infos
     * @returns {Promise<void>}
     */
    async getRoomInfos() {
      this.loading = true

      try {
        const response = (await this.axios.post('room/general/info', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data.room_general

        this.snapshot = !_.isUndefined(response.room_snapshot) ? response.room_snapshot : null
        this.pdf = !_.isUndefined(response.room_pdf_report) ? response.room_pdf_report : null

        this.room_kind = !_.isUndefined(response.room_kind) ? response.room_kind : null

        this.init_room_name = !_.isUndefined(response.room_display_name) ? response.room_display_name : null
        this.init_is_active = !_.isUndefined(response.room_active_room) ? response.room_active_room : null
        this.init_is_visible = !_.isUndefined(response.room_visible_room) ? response.room_visible_room : null
        this.init_no_moderation = !_.isUndefined(response.room_moderation_room) ? response.room_moderation_room : null

        this.init_room_has_password = !_.isUndefined(response.encrypted_password) ? (!_.isNull(response.encrypted_password)) : false

        // update v-model values with initial values
        this.resetValues()

        this.displaySnapshot = !_.isEmpty(this.snapshot) ? process.env.VUE_APP_API_ROHAN_PATH + this.snapshot : 'https://via.placeholder.com/500x368'
        this.displayPdf = !_.isEmpty(this.pdf) ? process.env.VUE_APP_API_ROHAN_PATH + this.pdf : 'https://via.placeholder.com/512x512'

      } catch (e) {
        if (e.response?.status !== undefined) {
          switch (e.response.status) {

              //Invalid parameters
            case 400:
              this.alert('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_get.error.invalid_params'), true)
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.alert('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_get.error.invalid_method'), true)
              break

            default:
              this.alert('error', 'mdi-alert-circle-outline', this.$t('room.general.room_info_get.error.unknown'), true)
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Event that fires when image detected on drag n drop zone or user uploads a picture
     *
     * Loads File image into cropper
     *
     * */
    onChangeFileUpload(e, type) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files[0].type.includes('image')) {
        this.dragging = false
        this.alert('error', 'mdi-alert-octagon', this.$t('room.general.image.form.cropper.error.not_authorized'), true)
        return
      }

      if (!files.length) {
        this.dragging = false;
        this.alert('error', 'mdi-alert-octagon', this.$t('room.general.image.form.cropper.error.upload_error'), true)
        return;
      }

      if (files[0].size >= 2500000 ){
        this.dragging = false;
        this.alert('error', 'mdi-alert-octagon', this.$t('room.general.image.form.cropper.error.file_size'), true)
        return;
      }

      this.selectImage(files[0], type);
    },
    /**
     * Event on drag enter on file upload in dialog
     * */
    dragEnter() {
      this.ui.alert.enable = false
      this.dragging = true
    },
    /**
     * Creates Object URL from cropped picture
     * */
    selectImage(image, type) {
      this.loadingImage = true
      if (type === 'snapshot') {
        this.currentSnapshot = image;
        this.previewSnapshot = URL.createObjectURL(this.currentSnapshot);
      }

      if (type === 'pdf_image') {
        this.currentPdf = image;
        this.previewPdf = URL.createObjectURL(this.currentPdf);
      }

      this.dragging = false;
    },

    /**
     * On cropper image change
     * */
    onChange({coordinates, image}) {
      this.result = {
        coordinates,
        image
      };
    },
    /**
     * Event fired when cropper has finished loading image
     * */
    ready() {
      this.loadingImage = false
    },
    /**
     * Event fired when cropper cannot load image
     * */
    onError() {
      this.loadingImage = false
      this.previewPdf = null
      this.previewSnapshot = null

      this.alert('error', 'mdi-alert-circle-outline', this.$t('room.general.image.form.cropper.error.load_error'), true)
    },
    /**
     * save image in db
     * if it's pdf or snapshot
     * url changed
     * @returns {Promise<void>}
     */
    async save(type) {
      this.loadingImage = true

      try {
        const {canvas} = this.$refs.cropper.getResult();
        if (canvas) {
          const form = new FormData();
          form.append('auth_token', this.$session.get('token'))
          form.append('room_key', this.$route.params.room_key)

          canvas.toBlob(blob => {
            form.append(type, blob);
            /* If type snapshot us room/snapshot/edit else room/pdfreport/edit */
            this.axios.post(type === 'snapshot' ? 'room/snapshot/edit' : 'room/pdfreport/edit', form,
                {
                  headers: {'Content-Type': 'multipart/form-data'}
                }).then(async response => {
              if (response.status === 200) {

                await this.getRoomInfos().then(() => {

                  if (type === 'snapshot') {
                    this.loadingSnapshot = false
                    this.dialogSnapshot = false
                  }
                  if (type === 'pdf_image') {
                    this.loadingPdf = false
                    this.dialogPdf = false
                  }

                  this.previewSnapshot = false
                  this.currentSnapshot = undefined

                  this.previewPdf = false
                  this.currentPdf = undefined

                  this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.general.image.form.success_message'), '4000')

                })
              }
            }).catch(e => {
              if (e.response?.status !== undefined) {
                switch (e.response.status) {
                    // Invalid params
                  case 400:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.image.form.error.invalid_params'))
                    break

                    // Invalid token
                  case 401:
                    this.$session.destroy()
                    this.$router.push({name: "Home"})
                    break

                    // User does not have access to room
                  case 402:
                    this.$router.push({name: "Home"}).then(() => {
                      this.$root.$emit('home-error', 'no-access')
                    })
                    break

                    // Invalid image type or action type
                  case 403:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.image.form.error.invalid_image'))
                    break

                    // Room does not exist
                  case 404:
                    this.$router.push({name: "Home"}).then(() => {
                      this.$root.$emit('home-error', 'no-room')
                    })
                    break

                    // Wrong method
                  case 405:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.image.form.error.invalid_method'))
                    break

                  default:
                    this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.general.image.form.error.unknown'))
                }
              }
            }).finally(() => {
              this.dialogSnapshot = false
              this.dialogPdf = false
              this.loadingImage = false
            })
          }, 'image/jpeg');
        }
      } catch (e) {
        console.log(e);
      }
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    maximize() {
      const {cropper} = this.$refs;
      const center = {
        left: cropper.coordinates.left + cropper.coordinates.width / 2,
        top: cropper.coordinates.top + cropper.coordinates.height / 2,
      };
      cropper.setCoordinates([
        ({imageSize}) => ({
          width: imageSize.width,
          height: imageSize.height,
        }),
        ({coordinates}) => ({
          left: center.left - coordinates.width / 2,
          top: center.top - coordinates.height / 2,
        }),
      ]);
    },
    center() {
      this.$refs.cropper.setCoordinates(({coordinates, imageSize}) => ({
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      }));
    },
  }
  ,
  async mounted() {
    await this.getRoomInfos().then(() =>
        // emit event to topbar to init room name
        this.$root.$emit('room-name-changed', this.room_name, this.room_kind)
    )
  },
  destroyed() {
    if (this.previewSnapshot)
      URL.revokeObjectURL(this.previewSnapshot)
    if (this.previewPdf)
      URL.revokeObjectURL(this.previewPdf)

  }
}
</script>

<style lang="scss" scoped>
::v-deep {

  .v-snack__content {
    display: flex;
  }

  .v-snack__action {
    align-self: baseline !important;

    button .v-icon {
      font-size: 20px;
    }

    .snackbar-close {
      font-size: 20px;
    }
  }
}

.dropzone {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #91b0b3;
}

.dropzone:hover {
  border: 2px dashed #790346;
}

.dropzone:hover .dropzone-info {
  color: #790346 !important;
}

.dropzone-wrapper {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
}

.dropzone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropzone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

::v-deep {
  .v-progress-circular {
    margin: 1rem;
    color: RGBA(121, 7, 72, 1);
  }

  .v-dialog {
    overflow-y: hidden !important;
  }

  .buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .vue-advanced-cropper__foreground, .vue-advanced-cropper__background, .vue-advanced-cropper {
    max-height: 500px !important;
  }
}
</style>
