<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
    >
      <v-icon> {{ ui.snackbar.icon }}</v-icon>
      {{ ui.snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs"> mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->


    <!-- Room meeting links  -->
    <v-row>
      <v-card-title>
        {{ $t('room.meeting_link.title') }}
      </v-card-title>
    </v-row>
    <!-- IF ROOM TYPE IS PUBLIC -->
    <v-card-text v-if="is_public_room"> Your room meeting link:
      <a :href="copy_meeting_link"> {{ copy_meeting_link }} </a>

                  <!-- Button copy link     -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="copyMeetingLink(meeting_links[0].link)"
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-link</v-icon>
          </v-btn>
        </template>
        <span>Copier le lien</span>
      </v-tooltip>
                  <!-- Button regenerate link     -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="regenerateMeetingLink"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Regenerate link</span>
      </v-tooltip>
    </v-card-text>
    <!-- END IF ROOM TYPE IS PUBLIC -->

    <!-- IF ROOM TYPE IS NOT PUBLIC   -->
    <!--    -->
    <v-card class="mb-4 text-end" elevation="0" color="transparent">
      <v-btn outlined color="primary" class="ml-2 mb-10" @click="show_dialog_add=true" v-if="!is_public_room">
        {{ $t('room.meeting_link.add_meeting_link.action_button') }}
      </v-btn>

      <!-- DIALOG ADD NEW MEETING LINK     -->
      <v-dialog
          v-model="show_dialog_add"
          width="500">
        <v-overlay :value="add_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.meeting_link.add_meeting_link.form.title') }}
            <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-3 pb-3 time-block">
            {{ $t('room.meeting_link.add_meeting_link.form.data.start_time') }}
            <v-datetime-picker v-model="meeting_start" date-format="dd/MM/yyyy" :date-picker-props="dateProps"
                               :text-field-props="textFieldPropsStartTime">
              <template slot="dateIcon">
                <v-icon>mdi-calendar-month-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-calendar-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
          <v-card-text class="time-block">
            {{$t('room.meeting_link.add_meeting_link.form.data.end_time') }}
            <v-datetime-picker v-model="meeting_end" date-format="dd/MM/yyyy" :date-picker-props="dateProps"
                               :text-field-props="textFieldPropsEndTime">
              <template slot="dateIcon">
                <v-icon>mdi-calendar-month-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-calendar-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
          <v-card-text>
            {{ $t('room.meeting_link.add_meeting_link.form.data.comment') }}
            <v-textarea
                outlined
                clearable
                name="input-7-4"
                class="pt-5"
                :label="this.$t('room.meeting_link.add_meeting_link.form.data.comment')"
                v-model="add_meeting_comment"
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                :disabled="textFieldPropsStartTime.error || textFieldPropsEndTime.error"
                @click="addMeetingLink">
              {{$t('room.meeting_link.add_meeting_link.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END DIALOG ADD NEW MEETING LINK     -->


      <!-- DIALOG EDIT MEETING LINK     -->
      <v-dialog
          v-model="show_dialog_modify"
          width="500">
        <v-overlay :value="modify_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.meeting_link.edit_meeting_link.form.title') }}
            <v-icon color="white" @click="show_dialog_modify=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-3 pb-3 time-block">
            {{ $t('room.meeting_link.edit_meeting_link.form.data.start_time') }}
            <v-datetime-picker v-model="modify_start_time" date-format="dd/MM/yyyy" :date-picker-props="dateProps"
                               :text-field-props="textFieldPropsStartTime">
              <template slot="dateIcon">
                <v-icon>mdi-calendar-month-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-calendar-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
          <v-card-text class="time-block">
            {{ $t('room.meeting_link.edit_meeting_link.form.data.end_time') }}
            <v-datetime-picker v-model="modify_end_time" date-format="dd/MM/yyyy" :date-picker-props="dateProps"
                               :text-field-props="textFieldPropsEndTime">
              <template slot="dateIcon">
                <v-icon>mdi-calendar-month-outline</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-calendar-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-card-text>
          <v-card-text>
            {{ $t('room.meeting_link.edit_meeting_link.form.data.comment') }}
            <v-textarea
                outlined
                clearable
                name="input-7-4"
                class="pt-5"
                :label="this.$t('room.meeting_link.edit_meeting_link.form.data.comment')"
                v-model="modify_comment"
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                :disabled="textFieldPropsStartTime.error || textFieldPropsEndTime.error"
                @click="editMeetingLink">
              {{ $t('room.meeting_link.edit_meeting_link.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END DIALOG EDIT MEETING LINK     -->


      <!-- ROOM MEETING LINKS DATATABLE-->
      <div v-if="!is_public_room">
        <div class="">
          <div class="row justify-end">
            <v-col cols="12" sm="6" lg="3" md="4">
              <v-text-field
                  :placeholder="this.$t('room.meeting_link.list.datatable.search')"
                  v-model="datatable.search">
                <v-icon slot="prepend" color="primary">mdi-magnify</v-icon>
              </v-text-field>
            </v-col>
          </div>
        </div>

        <v-data-table style="max-height: 400px; overflow:auto" :headers="datatable.headers"
                      :items="meeting_links"
                      :items-per-page.sync="datatable.items_per_page"
                      :search="datatable.search"
                      @pagination="paginationHandler"
                      class="datatable"
        >
          <template v-slot:item.time_slot="{ item }">
                <span class="font-weight-bold">
                  {{
                    formatTimeslot(item)
                  }}
                </span>
          </template>
          <template v-slot:item.creator_email="{ item }" class="text-center">
          <span class="">
                  {{ item.creator_email }}
                </span>
          </template>
          <template v-slot:item.comment="{ item }" class="text-center">
          <span class="">
                  {{ item.comment }}
                </span>
          </template>
          <template v-slot:item.copy="{ item }" class="text-center">
            <v-icon @click="copyMeetingLink(item.link)" color="primary">mdi-link
            </v-icon>
          </template>
          <template v-slot:item.modify="{ item }" class="text-center">
            <v-icon @click="showModifyMeeting(item)" color="primary">mdi-cog
            </v-icon>
          </template>
          <template v-slot:item.delete="{ item }" class="text-center">
            <v-icon @click="showDeleteMeeting(item)" color="primary">mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <!--END ROOM MEETING LINKS DATATABLE-->
      </div>
      <!-- HIDDEN INPUT FOR MEETING LINK     -->
      <input
          v-on:focus="$event.target.select()"
          ref="meeting_link"
          readonly
          hidden
          :value="copy_meeting_link" />
      <!--END HIDDEN INPUT FOR MEETING LINK     -->

      <!--DIALOG DELETE MEETING LINK      -->
      <v-dialog
          v-model="show_dialog_delete"
          width="500">
        <v-overlay :value="delete_loading" absolute>
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-card>
          <v-card-title class="primary white--text justify-space-between">
            {{ $t('room.meeting_link.delete.title') }}
            <v-icon color="white" @click="show_dialog_delete = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class=" pt-3">
            {{ $t('room.meeting_link.delete.subtitle') }}
            <v-card-text class="pb-0" v-if="!_.isEmpty(dialog_delete_meeting_object.creator_email)"> created by:
                                                                                                     {{
                dialog_delete_meeting_object.creator_email
                                                                                                     }}
            </v-card-text>
            <v-card-text class="pt-0 pb-0" v-if="!_.isEmpty(dialog_delete_meeting_object.link)"> link:
                                                                                                 {{
                dialog_delete_meeting_object.link
                                                                                                 }}
            </v-card-text>
            <v-card-text class="pt-0 pb-0" v-if="!_.isEmpty(dialog_delete_meeting_object.comment)"> comment:
                                                                                                    {{
                dialog_delete_meeting_object.comment
                                                                                                    }}
            </v-card-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="success"
                text
                @click="deleteMeetingLink"
            >
              {{ $t('room.meeting_link.delete.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--END DIALOG DELETE MEETING LINK      -->
    </v-card>
    <!--END BLOCK IF ROOM TYPE IS NOT PUBLIC   -->
    <!--    -->

  </v-card>
</template>

<script>
import keepSessionData from "@/tools/KeepSessionData";

export default {
  name: "MeetingLink",
  mixins: [keepSessionData],
  data() {
    return {
      is_public_room: false,
      // meeting links
      meeting_links: [],
      datatable: {
        search: '',
        headers: [],
        options: {},
        current_page: 1,
        items_per_page: parseInt(this.restoreData('pagination',5)),
      },

      // copy meeting link
      copy_meeting_link: '',

      // add meeting link
      add_loading: false,
      show_dialog_add: false,
      add_meeting_comment: '',
      meeting_start: new Date(),
      meeting_end: new Date(),
      dateProps: {
        'first-day-of-week': '1',
        'locale': 'fr-fr',
        'error': true,
      },
      textFieldPropsStartTime: {
        'error': false,
        'error_required': false,
        'error-messages': ''
      },
      textFieldPropsEndTime: {
        'error': false,
        'error_required': false,
        'error-messages': ''
      },
      // date format options when sending dates for new meeting links
      date_format_options: {
        year: "2-digit",
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: 'numeric',
        hour12: false
      },
      date_format_options_list: {
        year: "2-digit",
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: false
      },

      // edit meeting link
      show_dialog_modify: false,
      modify_loading: false,
      dialog_modify_meeting_object: {},
      modify_start_time: new Date(),
      modify_end_time: new Date(),
      modify_comment: '',

      // delete meeting link
      show_dialog_delete: false,
      dialog_delete_meeting_object: '',
      delete_loading: false,

      // ui
      loading: false,
      ui: {
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1,
          multiline: false
        }
      }
    }
  },
  watch: {
    /**
     * Clean add dialog on close
     * */
    show_dialog_add: function () {
      this.meeting_start = new Date()
      this.meeting_end = new Date()
      this.add_meeting_comment = ''
    },
    /**
     * Validate datetime for meeting start
     * */
    meeting_start: function () {

      // clear meeting end error message if not null
      if (!this.textFieldPropsEndTime.error_required) {
        this.textFieldPropsEndTime.error = false
        this.textFieldPropsEndTime['error-messages'] = ''
      }

      // clear error message if not null
      if (this.meeting_start !== null) {
        this.textFieldPropsStartTime.error_required = false
        this.textFieldPropsStartTime.error = false
        this.textFieldPropsStartTime["error-messages"] = ''
      }
      // set error message if null
      if (this.meeting_start === null) {
        this.textFieldPropsStartTime.error = true
        this.textFieldPropsStartTime.error_required = true
        this.textFieldPropsStartTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.required')

        // only compare if meeting end is not null
      } else if (this.meeting_end !== null) {

        if (this.meeting_end.getTime() < this.meeting_start.getTime()) {
          this.textFieldPropsStartTime.error = true
          this.textFieldPropsStartTime.error_required = false
          this.textFieldPropsStartTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.start_time')
        } else {
          this.textFieldPropsStartTime.error = false
          this.textFieldPropsStartTime['error-messages'] = ''
        }
      }
    },
    /**
     * Validate datetime for meeting end
     * */
    meeting_end: function () {
      // clear meeting start error message if not null
      if (!this.textFieldPropsStartTime.error_required) {
        this.textFieldPropsStartTime.error = false
        this.textFieldPropsStartTime['error-messages'] = ''
      }
      // clear error message if not null
      if (this.meeting_end !== null) {
        this.textFieldPropsEndTime.error_required = false
        this.textFieldPropsEndTime.error = false
        this.textFieldPropsEndTime["error-messages"] = ''
      }

      // set error message if null
      if (this.meeting_end === null) {
        this.textFieldPropsEndTime.error = true
        this.textFieldPropsEndTime.error_required = true
        this.textFieldPropsEndTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.required')

        // only compare if meeting start is not null
      } else if (this.meeting_start !== null) {

        if (this.meeting_end.getTime() < this.meeting_start.getTime()) {
          this.textFieldPropsEndTime.error_required = false
          this.textFieldPropsEndTime.error = true
          this.textFieldPropsEndTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.end_time')
        } else {
          this.textFieldPropsEndTime.error = false
          this.textFieldPropsEndTime['error-messages'] = ''
        }
      }
    },
    /**
     * Validate datetime for meeting start
     * */
    modify_start_time: function () {

      // clear meeting end error message if not null
      if (!this.textFieldPropsEndTime.error_required) {
        this.textFieldPropsEndTime.error = false
        this.textFieldPropsEndTime['error-messages'] = ''
      }

      // clear error message if not null
      if (this.modify_start_time !== null) {
        this.textFieldPropsStartTime.error_required = false
        this.textFieldPropsStartTime.error = false
        this.textFieldPropsStartTime["error-messages"] = ''
      }
      // set error message if null
      if (this.modify_start_time === null) {
        this.textFieldPropsStartTime.error = true
        this.textFieldPropsStartTime.error_required = true
        this.textFieldPropsStartTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.required')

        // only compare if meeting end is not null
      } else if (this.modify_end_time !== null) {

        if (this.modify_end_time.getTime() < this.modify_start_time.getTime()) {
          this.textFieldPropsStartTime.error = true
          this.textFieldPropsStartTime.error_required = false
          this.textFieldPropsStartTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.start_time')
        } else {
          this.textFieldPropsStartTime.error = false
          this.textFieldPropsStartTime['error-messages'] = ''
        }
      }
    },
    /**
     * Validate datetime for meeting end
     * */
    modify_end_time: function () {
      // clear meeting start error message if not null
      if (!this.textFieldPropsStartTime.error_required) {
        this.textFieldPropsStartTime.error = false
        this.textFieldPropsStartTime['error-messages'] = ''
      }
      // clear error message if not null
      if (this.modify_end_time !== null) {
        this.textFieldPropsEndTime.error_required = false
        this.textFieldPropsEndTime.error = false
        this.textFieldPropsEndTime["error-messages"] = ''
      }

      // set error message if null
      if (this.modify_end_time === null) {
        this.textFieldPropsEndTime.error = true
        this.textFieldPropsEndTime.error_required = true
        this.textFieldPropsEndTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.required')

        // only compare if meeting start is not null
      } else if (this.modify_start_time !== null) {

        if (this.modify_end_time.getTime() < this.modify_start_time.getTime()) {
          this.textFieldPropsEndTime.error_required = false
          this.textFieldPropsEndTime.error = true
          this.textFieldPropsEndTime['error-messages'] = this.$t('room.meeting_link.add_meeting_link.form.rules.end_time')
        } else {
          this.textFieldPropsEndTime.error = false
          this.textFieldPropsEndTime['error-messages'] = ''
        }
      }
    }
  },
  created() {
    // Define translations
    this.datatable.headers = [
      {
        value: 'time_slot',
        sortable: true,
        filterable: true,
        text: this.$t('room.meeting_link.list.datatable.time')
      },
      {
        value: 'creator_email',
        sortable: true,
        text: this.$t('room.meeting_link.list.datatable.creator')
      },
      {
        value: 'comment',
        text: this.$t('room.meeting_link.list.datatable.comment')
      },
      {
        text: this.$t('room.meeting_link.list.datatable.copy'),
        align: 'center',
        value: 'copy',
        sortable: false
      },
      {
        text: this.$t('room.meeting_link.list.datatable.edit'),
        align: 'center',
        value: 'modify',
        sortable: false
      },
      {
        text: this.$t('room.meeting_link.list.datatable.delete'),
        align: 'center',
        value: 'delete',
        sortable: false
      }
    ]
  },
  methods: {
    /**
     * @param {Object} e
     */
    paginationHandler(e) {
      if (this.datatable.items_per_page === e.itemsPerPage)
        this.keepData('pagination', e.itemsPerPage)
    },
    /**
     * Fetch room meeting links
     *
     * @return {Promise<void>}
     */
    async fetchMeetingLinks() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/meeting/list', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key
        })).data

        this.meeting_links = response.room_meetings

        if (this.is_public_room)
          this.generateMeetingLink(this.meeting_links[0].link)

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.list.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Add meeting link to the room
     *
     * @return {Promise<void>}
     */
    async addMeetingLink() {
      this.add_loading = true

      try {
        await this.axios.post('/room/meeting/add', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          start_time: Math.floor(new Date(this.meeting_start).getTime() / 1000),
          end_time: Math.floor(new Date(this.meeting_end).getTime() /1000 ),
          comment: this.add_meeting_comment
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.meeting_link.add_meeting_link.form.success_message'), '4000')

        await this.fetchMeetingLinks()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.add_meeting_link.form.error.invalid_params'), '4000')
              break

              //Invalid start time and end time
            case 300:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.add_meeting_link.form.error.invalid_time'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.add_meeting_link.form.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.add_meeting_link.form.error.unknown'), '4000')
          }
        }
      } finally {
        this.add_loading = false
        this.show_dialog_add = false
      }
    },
    /**
     * Delete meeting link
     * @return {Promise<void>}
     * */
    async deleteMeetingLink() {
      this.delete_loading = true

      try {
        await this.axios.post('/room/meeting/delete', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          meeting_link: this.dialog_delete_meeting_object.link,
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.meeting_link.delete.success_message'), '4000')

        await this.fetchMeetingLinks()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Meeting link does not exist or is not in this room
            case 403:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.meeting_not_exist'), '4000')
              await this.fetchMeetingLinks()
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.invalid_method'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.unknown'), '4000')
          }
        }
      } finally {
        this.delete_loading = false
        this.show_dialog_delete = false
      }
    },
    /**
     * Show delete meeting dialog
     * */
    showDeleteMeeting(param) {
      this.show_dialog_delete = true
      this.dialog_delete_meeting_object = param
    },
    /**
     * Shows dialog for modify meeting link
     * */
    showModifyMeeting(obj) {
      this.show_dialog_modify = true
      this.dialog_modify_meeting_object = obj
      this.modify_start_time = new Date(obj.time_begin)
      this.modify_end_time = new Date(obj.time_end)
      this.modify_comment = obj.comment
    },
    /**
     * Edit meeting link
     * */
    async editMeetingLink() {
      this.modify_loading = true

      try {
        await this.axios.post('/room/meeting/edit', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          meeting_link: this.dialog_modify_meeting_object.link,
          start_time: Math.floor(new Date(this.modify_start_time).getTime() / 1000),
          end_time: Math.floor(new Date(this.modify_end_time).getTime() /1000 ),
          comment: this.modify_comment
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.meeting_link.edit_meeting_link.form.success_message'), '4000')

        await this.fetchMeetingLinks()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              // Invalid start & end time for meeting
            case 300:
              this.snackbar('warning', 'mdi-alert-circle-outline', this.$t('room.meeting_link.edit_meeting_link.form.error.invalid_time'), '4000')
              break

              // Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.edit_meeting_link.form.error.invalid_params'), '4000')
              break

              // Invalid auth token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Meeting does not exist or does not belong to room
            case 403:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.edit_meeting_link.form.error.meeting_not_exist'), '4000')
              await this.fetchMeetingLinks()
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.invalid_method'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.meeting_link.delete.error.unknown'), '4000')
          }
        }
      } finally {
        this.modify_loading = false
        this.show_dialog_modify = false
      }
    },
    /**
     * Regenerates meeting link
     *
     *  */
    async regenerateMeetingLink() {
      this.loading = true

      try {
        await this.axios.post('/room/meeting/regen', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          meeting_link: this.meeting_links[0].link,
        })

        this.snackbar('success', 'mdi-check-circle-outline', 'Regenerated.', '4000')

        await this.fetchMeetingLinks()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              // Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid values.', '4000')
              break

              // Invalid auth token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Meeting does not exist or does not belong to room
            case 403:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Meeting link does not exist', '4000')
              await this.fetchMeetingLinks()
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid method.', '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch room type
     * @return {Promise<void>}
     */
    async fetchRoomType() {
      this.loading = true
      try {
        const response = (await this.axios.post('/room/type', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data

        if (!this._.isUndefined(response.room_kind) && response.room_kind === 2)
          this.is_public_room = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid value for animator reserved spots.', '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }

        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Formats start time and end time for datatable
     * */
    formatTimeslot(obj) {
      // create Date object from database date string (date is saved as string UTC)
      let datetime_time_start_init = new Date(obj.time_begin)
      let datetime_time_end_init = new Date(obj.time_end)

      // calculate timezone offset between user and UTC saved valued in DB
      let offset_datetime_start = datetime_time_start_init.getTimezoneOffset()
      let offset_datetime_end = datetime_time_end_init.getTimezoneOffset()

      // modify datetime start and datetime end to take into account time zone difference
      let datetime_time_start = new Date(datetime_time_start_init.getTime() + (offset_datetime_start * 60000 ))
      let datetime_time_end = new Date(datetime_time_end_init.getTime() + ( offset_datetime_end * 60000))

      // if the same date return only hour for time end
      if (datetime_time_start.getDate() === datetime_time_end.getDate()) {
        return datetime_time_start.toLocaleString('en-gb', this.date_format_options_list).replace(',', '') + ' - ' +
            datetime_time_end.toLocaleString('en-gb', {hour: 'numeric', minute: '2-digit',})
      }  else
          // return full dates
        return datetime_time_start.toLocaleString('en-gb', this.date_format_options_list).replace(',', '') + ' - ' +
            datetime_time_end.toLocaleString('en-gb', this.date_format_options_list).replace(',', '')

    },
    /**
     * Copy meeting link to user clipboard
     *
     * @param link : string
     */
    async copyMeetingLink(link) {
      // generate meeting link in hidden input
      this.generateMeetingLink(link)

      // focus hidden input
      this.$refs.meeting_link.focus();

      // copy link to clipboard
      document.execCommand('copy');
      await navigator.clipboard.writeText(this.copy_meeting_link);

      this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.meeting_link.copy.success_message'), '4000')
    },

    generateMeetingLink(link) {
      // generate meeting link in hidden input
      this.copy_meeting_link = process.env.VUE_APP_WEBSITE + process.env.VUE_APP_WEBSITE_LINK + '?link=' + link
    },
    /**
     *
     * @param type
     * @param icon
     * @param message
     * @param timeout
     * @param multiline
     */
    snackbar(type, icon, message, timeout, multiline) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
      this.ui.snackbar.multiline = multiline
    },

  },
  async mounted() {
    await this.fetchRoomType().then(async () => {
      await this.fetchMeetingLinks()
    })

  }
}

</script>

<style lang="scss" scoped>
::v-deep {
  .time-block {
    .v-input {
      padding-top: 0px !important;
    }
  }
}
</style>
