<template>
  <v-card flat class="pa-6">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- SNACKBAR   -->
    <v-snackbar
        app
        top
        right
        transition='slide-x-reverse-transition'
        v-model="ui.snackbar.show"
        :color="ui.snackbar.type"
        :timeout="ui.snackbar.timeout"
        :multi-line="ui.snackbar.multiline"
    >
      <v-icon class="align-baseline"> {{ ui.snackbar.icon }}</v-icon>
      <v-col class="pt-0 pb-0 pr-0">
        <span v-html="ui.snackbar.message"></span>
      </v-col>
      <template v-slot:action="{ attrs }">
        <v-icon @click="ui.snackbar.show = false" v-bind="attrs" class="pt-2 snackbar-close">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <!--END SNACKBAR   -->

    <!-- Animator spot   -->
    <v-row>
      <v-card-title>
        {{ $t('room.management.animator.title') }}
      </v-card-title>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card-text>
          {{ $t('room.management.animator.subtitle') }}
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
            v-model.number="reserved_animator"
            outlined
            dense
            type="number"
            @change="editReservedAnimator"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider
        class="mt-3 mb-3"
        color="primary"
    ></v-divider>
    <!-- End Animator spot   -->

    <!-- Room activity reporting  -->
    <v-row>
      <v-card-title>
        {{ $t('room.management.room_activity_reporting.title') }}
      </v-card-title>
    </v-row>
    <v-card-text>
      {{ $t('room.management.room_activity_reporting.subtitle')}}
    </v-card-text>
    <v-card-text>
      {{ $t('room.management.room_activity_reporting.frequency_message') }}
      <v-checkbox
          v-model="email_frequency"
          :label="this.$t('room.management.room_activity_reporting.frequency.daily')"
          dense
          hide-details
          value="room_activity_report_daily"
          @change="editRoomReporting"
      ></v-checkbox>
      <v-checkbox
          v-model="email_frequency"
          dense
          hide-details
          :label="this.$t('room.management.room_activity_reporting.frequency.weekly')"
          value="room_activity_report_weekly"
          @change="editRoomReporting"
      ></v-checkbox>
      <v-checkbox
          v-model="email_frequency"
          dense
          hide-details
          :label="this.$t('room.management.room_activity_reporting.frequency.monthly')"
          value="room_activity_report_monthly"
          @change="editRoomReporting"
      ></v-checkbox>
<!--      <v-checkbox
          v-model="email_frequency"
          dense
          hide-details
          :label="this.$t('room.management.room_activity_reporting.frequency.yearly')"
          value="room_activity_report_yearly"
          @change="editRoomReporting"
      ></v-checkbox>-->
    </v-card-text>
    <v-divider
        class="mt-3 mb-3"
        color="primary"
    ></v-divider>
    <!-- End room activity reporting  -->

    <!-- Room members  -->
    <!-- HIDE IF PUBLIC ROOM   -->
    <div v-if="!is_public_room">
      <v-row>
        <v-card-title>
          {{ $t('room.management.member.title') }}
        </v-card-title>
      </v-row>
      <v-card class="mb-4 text-end" elevation="0" color="transparent">
        <v-btn outlined color="primary" class="ml-2 mb-10" @click="show_dialog_add=true">
          {{ $t('room.management.member.add_member.action_button') }}
        </v-btn>
        <!-- DIALOG ADD NEW MEMBERS     -->
        <v-dialog
            v-model="show_dialog_add"
            width="500"
        >
          <v-overlay :value="modify_loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{$t('room.management.member.add_member.form.title')}}
              <v-icon color="white" @click="show_dialog_add=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-3 pb-3">
              {{$t('room.management.member.add_member.form.subtitle')}}
              <v-textarea
                  outlined
                  clearable
                  autofocus
                  name="input-7-4"
                  class="pt-5"
                  :label="this.$t('room.management.member.add_member.form.data.user_email')"
                  v-model="add_user_email"
              ></v-textarea>
              <v-checkbox
                  v-model="user_to_add_is_animator"
                  :label="this.$t('room.management.member.add_member.form.data.animator')"
                  dense
                  hide-details
              ></v-checkbox>
              <v-checkbox
                  v-model="user_to_add_can_create_link"
                  dense
                  hide-details
                  :label="this.$t('room.management.member.add_member.form.data.create_link')"
              ></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="success"
                  text
                  :disabled="_.isEmpty(add_user_email)"
                  @click="addMembers"
              >
                {{ $t('room.management.member.add_member.form.button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="">
          <div class="row justify-end">
            <v-col cols="12" sm="6" lg="3" md="4">
              <v-text-field
                  :placeholder="this.$t('room.management.member.list.datatable.search')"
                  v-model="datatable.search">
                <v-icon slot="prepend" color="primary">mdi-magnify</v-icon>
              </v-text-field>
            </v-col>
          </div>
        </div>
        <!-- ROOM MEMBERS DATATABLE-->
        <v-data-table style="max-height: 400px; overflow:auto" :headers="datatable.headers"
                      :items="members"
                      :items-per-page.sync="datatable.items_per_page"
                      :search="datatable.search"
                      @pagination="paginationHandler"
                      class="datatable"
        >
          <template v-slot:item.user_email="{ item }">
                <span class="font-weight-bold">
                  {{ item.user_email }}
                </span>
          </template>
          <template v-slot:item.user_nickname="{ item }" class="text-center">
          <span class="font-weight-bold">
                  {{ item.user_nickname }}
                </span>
          </template>
          <template v-slot:item.is_animator="{ item }" class="text-center">
            <v-icon v-if="item.is_animator" color="green">mdi-check</v-icon>
            <v-icon clas v-else color="red">mdi-circle-off-outline</v-icon>
          </template>
          <template v-slot:item.can_create_link="{ item }" class="text-center">
            <v-icon v-if="item.can_create_link" color="green">mdi-check</v-icon>
            <v-icon clas v-else color="red">mdi-circle-off-outline</v-icon>
          </template>
          <template v-slot:item.active="{ item }" class="text-center">
            <v-icon v-if="item.status_active" color="green">mdi-check</v-icon>
            <v-icon clas v-else color="red">mdi-circle-off-outline</v-icon>
          </template>
          <template v-slot:item.modify="{ item }" class="text-center">
            <v-icon @click="showModifyMember(item)" color="primary">mdi-cog
            </v-icon>
          </template>
          <template v-slot:item.delete="{ item }" class="text-center">
            <v-icon @click="showDeleteMember(item)" color="primary">mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <!--END ROOM MEMBERS DATATABLE-->

        <!--DIALOG MODIFY MEMBER      -->
        <v-dialog
            v-model="show_dialog_modify"
            width="500"
        >
          <v-overlay :value="modify_loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{ $t('room.management.member.edit_member.title') }}
              <v-icon color="white" @click="show_dialog_modify=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-3">
              {{ $t('room.management.member.edit_member.subtitle') }}
              <strong>{{ !_.isUndefined(dialog_modify_user_object.user_email) ? dialog_modify_user_object.user_email : '' }} </strong>
              <v-checkbox
                  v-model="modify_user"
                  :label="this.$t('room.management.member.edit_member.animator')"
                  dense
                  hide-details
                  value="is_animator"
              ></v-checkbox>
              <v-checkbox
                  v-model="modify_user"
                  dense
                  hide-details
                  :label="this.$t('room.management.member.edit_member.create_link')"
                  value="can_create_link"
              ></v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="success"
                  text
                  @click="editMember"
              >
                {{$t('room.management.member.edit_member.button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--END DIALOG MODIFY MEMBER      -->

        <!--DIALOG DELETE MEMBER      -->
        <v-dialog
            v-model="show_dialog_delete"
            width="500"
        >
          <v-overlay :value="delete_loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-card-title class="primary white--text justify-space-between">
              {{$t('room.management.member.delete_member.title') }}
              <v-icon color="white" @click="show_dialog_delete=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class=" pt-3">
              {{$t('room.management.member.delete_member.subtitle') }}
              <strong>{{
                  !_.isUndefined(dialog_delete_user_object.user_email) ? dialog_delete_user_object.user_email : ''
                      }}</strong> ?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="success"
                  text
                  @click="deleteMember"
              >
                {{$t('room.management.member.delete_member.button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--END DIALOG DELETE MEMBER      -->
      </v-card>
      <v-divider
          class="mt-6 mb-6"
          color="primary"
      ></v-divider>
    </div>
    <!-- End room members  -->

    <!-- Room new member email  -->
    <v-row>
      <v-card-title>
        {{ $t('room.management.email_target.title') }}
      </v-card-title>
    </v-row>
    <v-card-text>
      {{ $t('room.management.email_target.subtitle') }}
      <v-checkbox
          v-model="email_target"
          :label="this.$t('room.management.email_target.target.admin')"
          dense
          hide-details
          value="team_email_admin"
          @change="editRoomReporting"
      ></v-checkbox>
      <v-checkbox
          v-model="email_target"
          dense
          hide-details
          :label="this.$t('room.management.email_target.target.member')"
          value="team_email_member"
          @change="editRoomReporting"
      ></v-checkbox>
      <v-checkbox
          v-model="email_target"
          dense
          hide-details
          :label="this.$t('room.management.email_target.target.animator')"
          value="team_email_animator"
          @change="editRoomReporting"
      ></v-checkbox>
      <v-checkbox
          v-model="email_target"
          dense
          hide-details
          :label="this.$t('room.management.email_target.target.nobody')"
          value="none"
          @change="waitBeforeSettingNone"
      ></v-checkbox>
    </v-card-text>
    <!-- End room new member email  -->
  </v-card>

</template>

<script>
import helpers from "../../../plugins/helpers";
import keepSessionData from "@/tools/KeepSessionData";

export default {
  name: "Management",
  mixins: [keepSessionData],
  data() {
    return {
      init_reserved_animator: null,
      reserved_animator: null,
      init_email_frequency: [],
      email_frequency: [],
      init_email_target: [],
      email_target: [],

      is_public_room: false,

      // members
      datatable: {
        search: '',
        headers: [],
        options: {},
        current_page: 1,
        items_per_page: parseInt(this.restoreData('pagination',5)),
      },
      members: [],

      // modify user
      show_dialog_modify: false,
      dialog_modify_user_object: '',
      init_dialog_modify_user: '',
      modify_user: [],
      modify_loading: false,

      // delete user
      show_dialog_delete: false,
      dialog_delete_user_object: '',
      delete_loading: false,

      // add user
      show_dialog_add: false,
      add_user_email: '', //textarea v-model for new members' emails
      user_to_add_emails: [], // array of user emails and logins to add
      user_to_add_is_animator: false,
      user_to_add_can_create_link: false,
      user_to_add_invalid_emails: [], // array of user invalid emails
      // ui
      loading: false,
      ui: {
        snackbar: {
          show: false,
          icon: '',
          message: '',
          type: '',
          timeout: -1,
          multiline: false
        }
      }
    }
  },
  watch: {
    /**
     * Watch on checkboxes for new member email target
     */
    email_target: function () {
      if (this._.includes(this.email_target, 'none')) {
        // clear none and select others
        if (this.email_target.indexOf('none') === 0 && this._.size(this.email_target) > 1) {
          this.email_target = this.email_target.filter(e => e !== 'none')
        }
        // clear others and keep none
        if (this.email_target.indexOf('none') > 0) {
          this.email_target = this.email_target.filter(e => e === 'none')
        }
      }
    },
    /**
     * Clean add member dialog on close
     * */
    show_dialog_add: function () {
      if (this.show_dialog_add === false) {
        this.add_user_email = ''
        this.user_to_add_is_animator = false
        this.user_to_add_can_create_link = false
      }
    }
  },
  created() {
    // Define translations
    this.datatable.headers = [
      {
        value: 'user_email',
        //sortable: true,
        filterable: true,
        text: this.$t('room.management.member.list.datatable.email')
      },
      {
        value: 'user_nickname',
        text: this.$t('room.management.member.list.datatable.nickname'),
        align: 'center'
      },
      {
        value: 'is_animator',
        sortable: false,
        text: this.$t('room.management.member.list.datatable.animator'),
        align: 'center'
      },
      {
        value: 'can_create_link',
        sortable: false,
        text: this.$t('room.management.member.list.datatable.create_link'),
        align: 'center'
      },
      {
        value: 'active',
        sortable: false,
        text: this.$t('room.management.member.list.datatable.status'),
        align: 'center'
      },
      {
        text: this.$t('room.management.member.list.datatable.edit'),
        value: 'modify',
        sortable: false,
        align: 'center'
      },
      {
        text: this.$t('room.management.member.list.datatable.delete'),
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ]
  },
  methods: {
    /**
     * @param {Object} e
     */
    paginationHandler(e) {
      if (this.datatable.items_per_page === e.itemsPerPage)
        this.keepData('pagination', e.itemsPerPage)
    },
    /**
     * Cleans all email addresses entered by the user
     * Saves array of invalid emails
     *
     * */
    cleanEmailsAddMembers() {
      //split all emails at new line
      let temp_emails = []
      temp_emails = this.add_user_email.split("\n")

      let emails = []

      this._.forEach(temp_emails, (e) => {
        if (helpers.checkEmailValid(e))
          emails.push(e)
        else
          this.user_to_add_invalid_emails.push(e)
      })

      // get all uniques
      emails = [...new Set(emails)]
      this.user_to_add_invalid_emails = [...new Set(this.user_to_add_invalid_emails)]

      this._.forEach(emails, (e) => {
        this.user_to_add_emails.push({
          email: e,
          login: e
        })
      })
    },
    /**
     * Adds members to the room
     * Input : array of user_emails and logins
     *
     * @return {Promise<void>}
     */
    async addMembers() {
      // clean emails
      this.cleanEmailsAddMembers()

      try {
        this.loading = true

        const response = (await this.axios.post('/room/member/add', {
          auth_token: !this._.isUndefined(this.$session.get('token')) ? this.$session.get('token') : null,
          room_key: this.$route.params.room_key,
          users_to_add: this.user_to_add_emails,
          user_animator: this.user_to_add_is_animator,
          user_create_link: this.user_to_add_can_create_link
        })).data

        this.show_dialog_add = false

        let array_email_exists = []
        let string_email_exists = ''

        let string_email_invalid = ''

        // if any of the users already exist && they have not been added to the db
        // save to string list of emails
        if (!this._.isEmpty(response.already_exists)) {
          this._.forEach(response.already_exists, r => {
            array_email_exists.push(r)
          })

          string_email_exists = array_email_exists.join('<br>')
        }

        // if any of the email addresses were invalid && have not been added to the db
        // save to string list of invalid emails
        if (!this._.isEmpty(this.user_to_add_invalid_emails)) {
          string_email_invalid = this.user_to_add_invalid_emails.join('<br')
        }

        // construct snackbar message
        let snackbar_message = this.$t('room.management.member.add_member.form.message.success_message')

        if (string_email_exists !== '')
          snackbar_message += this.$t('room.management.member.add_member.form.message.email_exist') + string_email_exists

        if (string_email_invalid !== '')
          snackbar_message += this.$t('room.management.member.add_member.form.message.email_invalid') + string_email_invalid

        this.snackbar('success', 'mdi-check-circle-outline', snackbar_message, '-1')

        await this.fetchRoomMembers()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.add_member.form.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.add_member.form.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.add_member.form.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }

      this.resetAddForm()
    },
    /**
     * Reset form in dialog add user
     * */
    resetAddForm() {
      this.user_to_add_emails = []
      this.user_to_add_is_animator = false
      this.user_to_add_can_create_link = false
      this.user_to_add_invalid_emails = []
    },
    /**
     * Show dialog for modify member and save params
     * */
    showModifyMember(param) {
      this.modify_user = []
      this.show_dialog_modify = true

      this.dialog_modify_user_object = param

      if (this.dialog_modify_user_object.is_animator === true)
        this.modify_user.push('is_animator')

      if (this.dialog_modify_user_object.can_create_link === true)
        this.modify_user.push('can_create_link')
    },
    /**
     * Edits member
     *
     * @return {Promise<void>}
     * */
    async editMember() {
      this.modify_loading = true

      try {
        await this.axios.post('/room/member/edit', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key,
          user_email: this.dialog_modify_user_object.user_email,
          user_animator: this._.includes(this.modify_user, 'is_animator') ? 1 : 0,
          user_create_link: this._.includes(this.modify_user, 'can_create_link') ? 1 : 0
        })

        this.show_dialog_modify = false

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.management.member.edit_member.success_message'), '4000')

        await this.fetchRoomMembers()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.edit_member.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"})
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.edit_member.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            case 418:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.edit_member.error.invalid_method'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.edit_member.error.invalid_method'), '4000')
          }
        }
      } finally {
        this.modify_loading = false
      }
    },
    /**
     * Deletes member
     * @return {Promise<void>}
     * */
    async deleteMember() {
      this.delete_loading = true

      try {
        await this.axios.post('/room/member/delete', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key,
          user_email: this.dialog_delete_user_object.user_email,
          user_active: this.dialog_delete_user_object.status_active
        })

        this.show_dialog_delete = false

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.management.member.delete_member.success_message'), '4000')

        await this.fetchRoomMembers()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.delete_member.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: "Home"})
              break

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"})
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.delete_member.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            case 418:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.delete_member.error.unknown'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.member.delete_member.error.unknown'), '4000')
          }
        }
      } finally {
        this.delete_loading = false
      }
    },
    /**
     * Show delete member dialog
     * */
    showDeleteMember(param) {
      this.show_dialog_delete = true
      this.dialog_delete_user_object = param
    },
    snackbar(type, icon, message, timeout, multiline) {
      this.ui.snackbar.message = message
      this.ui.snackbar.icon = icon
      this.ui.snackbar.type = type
      this.ui.snackbar.timeout = timeout
      this.ui.snackbar.show = true
      this.ui.snackbar.multiline = multiline
    },
    /**
     * Update number of reserved animator spots on input change
     * @return {Promise<void>}
     */
    async editReservedAnimator() {
      this.loading = true

      try {
        await this.axios.post('/room/management/edit', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key,
          animator_spot: this.reserved_animator
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.management.animator.success_message'), '4000')

        await this.fetchAnimatorSpot()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            case 418:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.error.unknown'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.error.unknown'), '4000')
          }
        }
        // restore values if not saved
        this.reserved_animator = this.init_reserved_animator

      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch animator spots for room
     * @return {Promise<void>}
     */
    async fetchAnimatorSpot() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/management/info', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data

        this.reserved_animator = response.room_management.room_animator_reserved_spot

        this.init_reserved_animator = this.reserved_animator

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"})
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.unknown'), '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch room reporting details
     * @return {Promise<void>}
     */
    async fetchRoomReporting() {
      try {
        this.loading = true

        const response = (await this.axios.post('/room/reporting/info', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data

        // email target for new members
        this.email_target = Object.keys(response.room_reporting.team_email).filter(e => response.room_reporting.team_email[e] === true)

        if (this._.size(this.email_target) === 0)
          this.email_target = ['none']

        this.init_email_target = this.email_target

        // email frequency for reports
        this.email_frequency = Object.keys(response.room_reporting.activity_report).filter(e => response.room_reporting.activity_report[e] === true)
        this.init_email_frequency = this.email_frequency

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.list.error.unknown'), '4000')
          }

          // restore values if not saved
          this.email_target = this.init_email_target
          this.email_frequency = this.init_email_frequency
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Update room reporting details
     * @return {Promise<void>}
     */
    async editRoomReporting() {
      this.loading = true

      try {
        await this.axios.post('/room/reporting/edit', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key,
          activity_report_daily: this._.includes(this.email_frequency, 'room_activity_report_daily'),
          activity_report_weekly: this._.includes(this.email_frequency, 'room_activity_report_weekly'),
          activity_report_monthly: this._.includes(this.email_frequency, 'room_activity_report_monthly'),
          email_target_admin: this._.includes(this.email_target, 'team_email_admin'),
          email_target_member: this._.includes(this.email_target, 'team_email_member'),
          email_target_animator: this._.includes(this.email_target, 'team_email_animator')
        })

        this.snackbar('success', 'mdi-check-circle-outline', this.$t('room.management.room_activity_reporting.success_message'), '4000')

        await this.fetchRoomReporting()

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.error.invalid_method'), '4000')
              break

              // Cannot save to DB
            case 418:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.error.invalid_method'), '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.room_activity_reporting.error.unknown'), '4000')
          }
        }
        this.reserved_animator = this.init_reserved_animator
      } finally {
        this.loading = false
      }
    },
    /**
     * Wait before updating because of watch on checkboxes
     */
    waitBeforeSettingNone() {
      setTimeout(() => {
        this.editRoomReporting()
      }, 100);
    },
    /**
     * Fetch room members
     * @return {Promise<void>}
     */
    async fetchRoomMembers() {
      this.loading = true

      try {
        const response = (await this.axios.post('/room/member/list', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data

        this.members = response.room_members

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.unknown'), '4000')
          }

          // restore values if not saved
          this.email_target = this.init_email_target
          this.email_frequency = this.init_email_frequency
        }
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetch room type
     * @return {Promise<void>}
     */
    async fetchRoomType() {
      try {
        const response = (await this.axios.post('/room/type', {
          auth_token: this.$session.get('token'),
          room_key: this.$route.params.room_key
        })).data

        if (!this._.isUndefined(response.room_kind) && response.room_kind === 2 )
          this.is_public_room = true

      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.invalid_params'), '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // User does not have access to room
            case 402:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-access')
              })
              break

              // Room does not exist
            case 404:
              await this.$router.push({name: "Home"}).then(() => {
                this.$root.$emit('home-error', 'no-room')
              })
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', this.$t('room.management.animator.list.error.unknown'), '4000')
          }

        }
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.fetchRoomType().then(async () => {
      if (!this.is_public_room)
        await this.fetchRoomMembers()
    })

    await this.fetchAnimatorSpot()
    await this.fetchRoomReporting()

  }
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
::v-deep {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .v-snack__content {
    display: flex;
  }

  .v-snack__action {
    align-self: baseline !important;

    button .v-icon {
      font-size: 20px;
    }

    .snackbar-close {
      font-size: 20px;
    }
  }
}
</style>
